import React, { useState, useEffect } from "react";
import Routes from "./routes";
import "react-toastify/dist/ReactToastify.css";

import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import { ptBR } from "@material-ui/core/locale";

import { system } from "./config.json";

const App = () => {
	const [locale, setLocale] = useState();

  const theme = createTheme(
    {
      scrollbarStyles: {
        '&::-webkit-scrollbar': {
          width: '8px',
          height: '8px',
        },
        '&::-webkit-scrollbar-thumb': {
          boxShadow: 'inset 0 0 6px rgba(0, 0, 0, 0.3)',
          backgroundColor: '#e8e8e8',
        },
      },
      palette: {
        primary: { main: system.color.lightTheme.palette.primary || "#6B62FE" },
        secondary: { main: system.color.lightTheme.palette.secondary || "#F50057" },
        toolbar: { main: system.color.lightTheme.palette.background || "#6B62FE" },
        menuItens: { main: system.color.lightTheme.palette.menuItens || "#ffffff" },
        sub: { main: system.color.lightTheme.palette.sub || "#ffffff" },
        toolbarIcon: { main: system.color.lightTheme.palette.default || "#ffffff"},
        divide: { main: system.color.lightTheme.palette.divide || "#E0E0E0" },
        danger: { main: '#4caf50' },
      },
      
    },
    locale
  );

	useEffect(() => {
		const i18nlocale = localStorage.getItem("i18nextLng");
		const browserLocale =
			i18nlocale.substring(0, 2) + i18nlocale.substring(3, 5);

		if (browserLocale === "ptBR") {
			setLocale(ptBR);
		}
	}, []);

	return (
		<ThemeProvider theme={theme}>
			<Routes />
		</ThemeProvider>
	);
};

export default App;
