import React, { useState, useContext } from "react";
//import { Link as RouterLink } from "react-router-dom";

import {
  Button,
  CssBaseline,
  TextField,
  Box,
  Typography,
  Container,
  InputAdornment,
  IconButton,
  Link
} from '@material-ui/core';

import { makeStyles } from "@material-ui/core/styles";
import { Visibility, VisibilityOff } from '@material-ui/icons';

import { i18n } from "../../translate/i18n";

import { AuthContext } from "../../context/Auth/AuthContext";
import logo from "../../assets/logo.png";
import { system } from "../../config.json";


 const Copyright = () => {
 	return (
 		<Typography variant="body2" color="textSecondary" align="center">
 			© {new Date().getFullYear()}
			 {" - "}
 			<Link color="inherit" href={system.url || "https://emawork.com"}  target="_blank">
               {system.name} - versão: {system.version}
            </Link>
            {"."}
 		</Typography>
 	);
 };

const useStyles = makeStyles(theme => ({
	paper: {
		marginTop: theme.spacing(8),
		display: "flex",
		flexDirection: "column",
		alignItems: "center",
	},
	avatar: {
		margin: theme.spacing(1),
		backgroundColor: theme.palette.secondary.main,
	},
	form: {
		width: "100%", // Fix IE 11 issue.
		marginTop: theme.spacing(1),
	},
	submit: {
		margin: theme.spacing(3, 0, 2),
	},
}));

const Login = () => {
	const classes = useStyles();

	const [user, setUser] = useState({ email: "", password: "" });

	const { handleLogin } = useContext(AuthContext);

	const [showPassword, setShowPassword] = useState(false);

	const handleChangeInput = e => {
		setUser({ ...user, [e.target.name]: e.target.value });
	};

	const handlSubmit = e => {
		e.preventDefault();
		handleLogin(user);
	};

	return (
		<Container component="main" maxWidth="xs">
			<CssBaseline />
			<div className={classes.paper}>
				<div>
					<img style={{ margin: "0 auto", height: "80px", width: "100%" }} src={logo} alt="Whats" />
				</div>
				{<Typography component="h1" variant="h5">
					{i18n.t("login.title")}
				</Typography> }
				<form className={classes.form} noValidate onSubmit={handlSubmit}>
					<TextField
						variant="outlined"
						margin="normal"
						required
						fullWidth
						id="email"
						label={i18n.t("login.form.email")}
						name="email"
						value={user.email}
						onChange={handleChangeInput}
						autoComplete="email"
						autoFocus
					/>
					<TextField
						variant="outlined"
						margin="normal"
						required
						fullWidth
						name="password"
						label={i18n.t("login.form.password")}
						id="password"
						value={user.password}
						onChange={handleChangeInput}
						autoComplete="current-password"
						type={showPassword ? 'text' : 'password'}
						InputProps={{
						endAdornment: (
							<InputAdornment position="end">
							<IconButton
								aria-label="toggle password visibility"
								onClick={() => setShowPassword((e) => !e)}
							>
								{showPassword ? <VisibilityOff color="secondary" /> : <Visibility color="secondary" />}
							</IconButton>
							</InputAdornment>
						)
						}}
					/>
					<Button
						type="submit"
						fullWidth
						variant="contained"
						color="primary"
						className={classes.submit}
					>
						{i18n.t("login.buttons.submit")}
					</Button>
					
				</form>
			</div>
			<Box mt={8}>{<Copyright />}</Box>
		</Container>
	);
};

export default Login;

/*
<Grid container>
						<Grid item>
							<Link
								href="#"
								variant="body2"
								component={RouterLink}
								to="/signup"
							>
								{i18n.t("login.buttons.register")}
							</Link>
						</Grid>
					</Grid>
*/