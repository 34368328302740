import React, { useEffect, useState } from "react";

import Grid from "@material-ui/core/Grid";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import FormHelperText from "@material-ui/core/FormHelperText";
import TextField from "@material-ui/core/TextField";
import useSettings from "../../hooks/useSettings";
import useAuth from "../../hooks/useAuth.js";
import { toast } from 'react-toastify';
import { makeStyles } from "@material-ui/core/styles";
import { grey, blue } from "@material-ui/core/colors";

const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  fixedHeightPaper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
    height: 240,
  },
  tab: {
    background: "#f2f5f3",
    borderRadius: 4,
    width: "100%",
    "& .MuiTab-wrapper": {
      color: "#128c7e"
    },
    "& .MuiTabs-flexContainer": {
      justifyContent: "center"
    }


  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    alignItems: "center",
    marginBottom: 12,
    width: "100%",
  },
  cardAvatar: {
    fontSize: "55px",
    color: grey[500],
    backgroundColor: "#ffffff",
    width: theme.spacing(7),
    height: theme.spacing(7),
  },
  cardTitle: {
    fontSize: "18px",
    color: blue[700],
  },
  cardSubtitle: {
    color: grey[600],
    fontSize: "14px",
  },
  alignRight: {
    textAlign: "right",
  },
  fullWidth: {
    width: "100%",
  },
  selectContainer: {
    width: "100%",
    textAlign: "left",
  },
}));

export default function Options(props) {
  const { settings, scheduleTypeChanged } = props;
  const classes = useStyles();
  const [currentUser, setCurrentUser] = useState({});
  const { getCurrentUserInfo } = useAuth();
  const [userRating, setUserRating] = useState("disabled");
  const [scheduleType, setScheduleType] = useState("disabled");
  const [callType, setCallType] = useState("enabled");
  const [chatbotType, setChatbotType] = useState("");
  const [CheckMsgIsGroup, setCheckMsgIsGroupType] = useState("enabled");
  const [autoBoletoModeType, setAutoBoletoModeType] = useState("enabled");
  const [sideMenuType, setSideMenuType] = useState("enabled");
  const [userApiTokenType, setUserApiTokenType] = useState("");

  const [loadingUserRating, setLoadingUserRating] = useState(false);
  const [loadingScheduleType, setLoadingScheduleType] = useState(false);
  const [loadingCallType, setLoadingCallType] = useState(false);
  const [loadingChatbotType, setLoadingChatbotType] = useState(false);
  const [setCheckMsgIsGroup] = useState(false);
  const [loadingAutoBoletoModeType, setLoadingAutoBoletoModeType] = useState(false);
  const [loadingSideMenuType, setLoadingSideMenuType] = useState(false);
  const [loadingUserApiTokenType, setLoadingUserApiTokenType] = useState("");


  const { update } = useSettings();

  useEffect(() => {
    async function findData() {
      try {
        const user = await getCurrentUserInfo();
        setCurrentUser(user);
      } catch (e) {
        toast.error(e);
      }
    }
    findData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (Array.isArray(settings) && settings.length) {
      const userRating = settings.find((s) => s.key === "userRating");
      if (userRating) {
        setUserRating(userRating.value);
      }
      const scheduleType = settings.find((s) => s.key === "scheduleType");
      if (scheduleType) {
        setScheduleType(scheduleType.value);
      }
      const callType = settings.find((s) => s.key === "call");
      if (callType) {
        setCallType(callType.value);
      }
      const CheckMsgIsGroup = settings.find((s) => s.key === "CheckMsgIsGroup");
      if (CheckMsgIsGroup) {
        setCheckMsgIsGroupType(CheckMsgIsGroup.value);
      }
      const chatbotType = settings.find((s) => s.key === "chatBotType");
      if (chatbotType) {
        setChatbotType(chatbotType.value);
      }
      const autoBoletoModeType = settings.find((s) => s.key === "autoBoletoMode");
      if (autoBoletoModeType) {
        setAutoBoletoModeType(autoBoletoModeType.value);
      }
      const sideMenuType = settings.find((s) => s.key === "sideMenu");
      if (sideMenuType) {
        setSideMenuType(sideMenuType.value);
      }
      const userApiTokenType = settings.find((s) => s.key === "userApiToken");
      if (userApiTokenType) {
        setUserApiTokenType(userApiTokenType.value);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [settings]);

  const isId = () => {
    return currentUser.id;
  };

  async function handleChangeUserRating(value) {
    setUserRating(value);
    setLoadingUserRating(true);
    await update({
      key: "userRating",
      value,
    });
    toast.success("Operação atualizada com sucesso.");
    setLoadingUserRating(false);
  }

  async function handleScheduleType(value) {
    setScheduleType(value);
    setLoadingScheduleType(true);
    await update({
      key: "scheduleType",
      value,
    });
    toast.success('Operação atualizada com sucesso.', {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: true,
      theme: "light",
    });
    setLoadingScheduleType(false);
    if (typeof scheduleTypeChanged === "function") {
      scheduleTypeChanged(value);
    }
  }

  async function handleCallType(value) {
    setCallType(value);
    setLoadingCallType(true);
    await update({
      key: "call",
      value,
    });
    toast.success("Operação atualizada com sucesso.");
    setLoadingCallType(false);
  }

  async function handleChatbotType(value) {
    setChatbotType(value);
    setLoadingChatbotType(true);
    await update({
      key: "chatBotType",
      value,
    });
    toast.success("Operação atualizada com sucesso.");
    setLoadingChatbotType(false);
  }

  async function handleAutoBoletoModeType(value) {
    setAutoBoletoModeType(value);
    setLoadingAutoBoletoModeType(true);
    await update({
      key: "autoBoletoMode",
      value,
    });
    toast.success("Operação atualizada com sucesso.");
    setLoadingAutoBoletoModeType(false);
  }

  async function handleSideMenuType(value) {
    setSideMenuType(value);
    setLoadingSideMenuType(true);
    await update({
      key: "sideMenu",
      value,
    });
    toast.success("Operação atualizada com sucesso.");
    setLoadingSideMenuType(false);
  }

  async function handleGroupType(value) {
    setCheckMsgIsGroupType(value);
    setCheckMsgIsGroup(true);
    await update({
      key: "CheckMsgIsGroup",
      value,
    });
    toast.success("Operação atualizada com sucesso.");
    setCheckMsgIsGroup(false);
  }

  async function handleUserApiTokenType(value) {
    setUserApiTokenType(value);
    setLoadingUserApiTokenType(true);
    await update({
      key: "userApiToken",
      value,
    });
    toast.success("Operação atualizada com sucesso.");
    setLoadingUserApiTokenType(false);
  }

  return (

    <>

      <Grid xs={12} sm={6} md={6} item>
        <FormControl className={classes.selectContainer}>
          <InputLabel id="ratings-label">Avaliações</InputLabel>
          <Select
            labelId="ratings-label"
            value={userRating}
            onChange={async (e) => {
              handleChangeUserRating(e.target.value);
            }}
          >
            <MenuItem value={"disabled"}>Desabilitadas</MenuItem>
            <MenuItem value={"enabled"}>Habilitadas</MenuItem>
          </Select>
          <FormHelperText>
            {loadingUserRating && "Atualizando..."}
          </FormHelperText>
        </FormControl>
      </Grid>

      <Grid xs={12} sm={6} md={6} item>
        <FormControl className={classes.selectContainer}>
          <InputLabel id="schedule-type-label">
            Gerenciamento de Expediente
          </InputLabel>
          <Select
            labelId="schedule-type-label"
            value={scheduleType}
            onChange={async (e) => {
              handleScheduleType(e.target.value);
            }}
          >
            <MenuItem value={"disabled"}>Desabilitado</MenuItem>
            <MenuItem value={"queue"}>Gerenciamento Por Fila</MenuItem>
            {/*<MenuItem value={"company"}>Gerenciamento Por Empresa</MenuItem>*/}
          </Select>
          <FormHelperText>
            {loadingScheduleType && "Atualizando..."}
          </FormHelperText>
        </FormControl>
      </Grid>

      <Grid xs={12} sm={6} md={6} item>
        <FormControl className={classes.selectContainer}>
          <InputLabel id="group-type-label">
            Ignorar Mensagens de Grupos
          </InputLabel>
          <Select
            labelId="group-type-label"
            value={CheckMsgIsGroup}
            onChange={async (e) => {
              handleGroupType(e.target.value);
            }}
          >
            <MenuItem value={"disabled"}>Desativado</MenuItem>
            <MenuItem value={"enabled"}>Ativado</MenuItem>
          </Select>
          <FormHelperText>
            {loadingScheduleType && "Atualizando..."}
          </FormHelperText>
        </FormControl>
      </Grid>

      <Grid xs={12} sm={6} md={6} item>
        <FormControl className={classes.selectContainer}>
          <InputLabel id="call-type-label">
            Aceitar Chamada
          </InputLabel>
          <Select
            labelId="call-type-label"
            value={callType}
            onChange={async (e) => {
              handleCallType(e.target.value);
            }}
          >
            <MenuItem value={"disabled"}>Não Aceitar</MenuItem>
            <MenuItem value={"enabled"}>Aceitar</MenuItem>
          </Select>
          <FormHelperText>
            {loadingCallType && "Atualizando..."}
          </FormHelperText>
        </FormControl>
      </Grid>

      <Grid xs={12} sm={6} md={6} item>
        <FormControl className={classes.selectContainer}>
          <InputLabel id="chatbot-type-label">
            Tipo Chatbot
          </InputLabel>
          <Select
            labelId="chatbot-type-label"
            value={chatbotType}
            onChange={async (e) => {
              handleChatbotType(e.target.value);
            }}
          >
            <MenuItem value={"text"}>Texto</MenuItem>
            <MenuItem value={"button"}>Botão</MenuItem>
            <MenuItem value={"list"}>Lista</MenuItem>
          </Select>
          <FormHelperText>
            {loadingChatbotType && "Atualizando..."}
          </FormHelperText>
        </FormControl>
      </Grid>

      <Grid xs={12} sm={6} md={6} item>
        <FormControl className={classes.selectContainer}>
          <InputLabel id="autoBoletoMode">
            2ª Via de Boleto Automático
          </InputLabel>
          <Select
            labelId="autoBoletoMode"
            value={autoBoletoModeType}
            onChange={async (e) => {
              handleAutoBoletoModeType(e.target.value);
            }}
          >
            <MenuItem value={"disabled"}>Não</MenuItem>
            <MenuItem value={"enabled"}>Sim</MenuItem>
          </Select>
          <FormHelperText>
            {loadingAutoBoletoModeType && "Atualizando..."}
          </FormHelperText>
        </FormControl>
      </Grid>

      <Grid xs={12} sm={6} md={6} item>
        <FormControl className={classes.selectContainer}>
          <InputLabel id="sideMenu">
            Menu Lateral Inicial
          </InputLabel>
          <Select
            labelId="sideMenu"
            value={sideMenuType}
            onChange={async (e) => {
              handleSideMenuType(e.target.value);
            }}
          >
            <MenuItem value={"disabled"}>Não</MenuItem>
            <MenuItem value={"enabled"}>Sim</MenuItem>
          </Select>
          <FormHelperText>
            {loadingSideMenuType && "Atualizando..."}
          </FormHelperText>
        </FormControl>
      </Grid>


      {isId() == 1 ?
        <Grid xs={12} sm={6} md={6} item>
          <FormControl className={classes.selectContainer}>
            <TextField
              id="userApiToken"
              name="userApiToken"
              margin="dense"
              label="Token"
              variant="outlined"
              value={userApiTokenType}
              onChange={async (e) => {
                handleUserApiTokenType(e.target.value);
              }}
            >
            </TextField>
            <FormHelperText>
              {loadingUserApiTokenType && "Atualizando..."}
            </FormHelperText>
          </FormControl>
        </Grid>
        : null}

    </>

  );

}
